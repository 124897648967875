import Navbar from "../components/Navbar";
import NavBarMobile from "../components/NavBarMobile";
import CmsPage from "../components/CmsPage";

import { useRouter } from "next/router";
import { loadPage } from "../lib/api-lib";

import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

function Page({ structure }) {
  return (
    <>
      <Navbar navigation={structure.navigation} />
      <NavBarMobile navigation={structure.navigation} />
      {typeof structure.page !== 'undefined' ? 
        <CmsPage structure={structure} />
      : ''}
    </>
  );
}

export async function getStaticProps({ locale }) {
  const data = await loadPage('homepage', locale); // homepage
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
      structure: data,
    },
    revalidate: 1,
  };
}

export default Page;
